import { render, staticRenderFns } from "./ReferenceFournitures.vue?vue&type=template&id=84cd1e1c&scoped=true&"
import script from "./ReferenceFournitures.vue?vue&type=script&lang=ts&"
export * from "./ReferenceFournitures.vue?vue&type=script&lang=ts&"
import style0 from "./ReferenceFournitures.vue?vue&type=style&index=0&id=84cd1e1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84cd1e1c",
  null
  
)

export default component.exports